import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { HealthRanges } from "@/utils/calculateHealthRanges";

interface HealthTableProps {
  healthRanges: HealthRanges;
}

export function HealthTable({ healthRanges }: HealthTableProps) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Status</TableHead>
          <TableHead>Score Range</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableCell>Excellent</TableCell>
          <TableCell>{`${healthRanges.excellentStart} and above`}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Good</TableCell>
          <TableCell>
            {`${healthRanges.goodStart} to ${healthRanges.goodEnd}`}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Average</TableCell>
          <TableCell>
            {`${healthRanges.averageStart} to ${healthRanges.averageEnd}`}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Poor</TableCell>
          <TableCell>{`Below ${healthRanges.poorEnd}`}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
