export const initialWeights = {
  // Positive metrics
  reply: 2.0,
  forward: 1.8,
  survey: 1.2,
  conversion: 2.5,
  timeSpent: 1.0,

  // Penalty metrics
  unsubscribe: 3.0,
  complaint: 3.0,
};

export const informationalWeights = {
  // Positive metrics
  reply: 2.4,
  forward: 1.725,
  survey: 1.0,
  conversion: 0.0,
  timeSpent: 1.2,

  // Penalty metrics
  unsubscribe: 2.5,
  complaint: 3.0,
};
