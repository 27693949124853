import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { FormFieldProps } from "@/types/email-calculator";
import { tooltips } from "@/constants/tooltips";

interface FormFieldComponentProps {
  disabled?: boolean;
  id: string;
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  props: FormFieldProps;
  value: number;
}

export function FormField({
  disabled,
  id,
  label,
  onChange,
  props,
  value,
}: FormFieldComponentProps) {
  return (
    <div className="grid gap-2">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Label htmlFor={id} className="flex items-center gap-2">
              {label}
            </Label>
          </TooltipTrigger>
          <TooltipContent>
            <p>{tooltips[id]}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <Input
        className="w-full"
        disabled={disabled}
        id={id}
        onChange={onChange}
        value={value}
        {...props}
      />
    </div>
  );
}
