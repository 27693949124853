export const averageCampaignFormData = {
  replyRate: 0.005,
  forwardingRate: 0.002,
  unsubscribeRate: 0.01,
  complaintRate: 0.001,
  surveyCompletionRate: 0.003,
  conversionRate: 0.002,
  timeSpentReading: 10,
  isInformational: false,
};

export const goodFormData = {
  replyRate: 0.02,
  forwardingRate: 0.01,
  unsubscribeRate: 0.002,
  complaintRate: 0.0001,
  surveyCompletionRate: 0.02,
  conversionRate: 0.01,
  timeSpentReading: 30,
  isInformational: false,
};

export const excellentCampaignFormData = {
  replyRate: 0.08,
  forwardingRate: 0.03,
  unsubscribeRate: 0.0005,
  complaintRate: 0.00005,
  surveyCompletionRate: 0.04,
  conversionRate: 0.03,
  timeSpentReading: 60,
  isInformational: false,
};
