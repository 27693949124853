export interface HealthRanges {
  excellentStart: number;
  goodStart: number;
  goodEnd: number;
  averageStart: number;
  averageEnd: number;
  poorEnd: number;
}

export const calculateHealthRanges = (baseScore: number, maxScore: number) => {
  const range = maxScore - baseScore;

  const poorEnd = Math.max(0, baseScore - range * 0.4);
  const averageStart = poorEnd;
  const averageEnd = baseScore + range * 0.2 - 0.01;
  const goodStart = averageEnd + 0.01;
  const goodEnd = baseScore + range * 0.6 - 0.01;
  const excellentStart = goodEnd + 0.01;

  return {
    excellentStart,
    goodStart,
    goodEnd,
    averageStart,
    averageEnd,
    poorEnd,
  };
};
