import { ScoringResult } from "@/types/email-calculator";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import { Badge } from "@/components/ui/badge";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

interface ResultsDisplayProps {
  result: ScoringResult;
}

export function ResultsDisplay({ result }: ResultsDisplayProps) {
  const getHealthStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case "excellent":
        return "bg-purple-500 hover:bg-purple-600";
      case "good":
        return "bg-green-500 hover:bg-green-600";
      case "average":
        return "bg-blue-500 hover:bg-blue-600";
      case "poor":
        return "bg-red-500 hover:bg-red-600";
      default:
        return "bg-gray-500 hover:bg-gray-600";
    }
  };

  const ScoreItem = ({
    label,
    value,
    max = 3,
  }: {
    label: string;
    value: number;
    max?: number;
  }) => (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="space-y-1.5">
            <div className="flex items-center justify-between text-sm">
              <span className="text-muted-foreground">{label}</span>
              <span className="font-medium">{value.toFixed(2)}</span>
            </div>
            <Progress value={(value / max) * 100} className="h-2" />
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p>
            Score: {value.toFixed(2)} out of {max}
          </p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );

  return (
    <Card className="w-full border-none shadow-none">
      <CardHeader className="pb-2 text-center">
        <div className="flex items-center justify-center gap-4 mb-2">
          <CardTitle className="text-3xl font-bold">
            {result.score}
            <span className="text-lg text-muted-foreground">/10</span>
          </CardTitle>
          <Badge
            className={`${getHealthStatusColor(
              result.healthStatus
            )} text-white`}
          >
            {result.healthStatus}
          </Badge>
        </div>
        <Badge variant="outline">{result.breakdown.emailType}</Badge>
      </CardHeader>

      <CardContent className="grid gap-6">
        {/* Positive Engagement Metrics */}
        <div className="space-y-4">
          <h3 className="text-lg font-semibold">Engagement Impact</h3>
          <div className="space-y-3">
            <ScoreItem
              label="Reply Impact"
              value={result.breakdown.engagementScores.replyScore}
            />
            <ScoreItem
              label="Forward Impact"
              value={result.breakdown.engagementScores.forwardScore}
            />
            <ScoreItem
              label="Survey Impact"
              value={result.breakdown.engagementScores.surveyScore}
            />
            <ScoreItem
              label="Conversion Impact"
              value={result.breakdown.engagementScores.conversionScore}
            />
            <ScoreItem
              label="Time Spent Impact"
              value={result.breakdown.engagementScores.timeScore}
            />
          </div>
        </div>

        {/* Penalties */}
        <div className="space-y-4">
          <h3 className="text-lg font-semibold text-destructive">Penalties</h3>
          <div className="space-y-3">
            <ScoreItem
              label="Unsubscribe Impact"
              value={result.breakdown.penalties.unsubscribe}
              max={5}
            />
            <ScoreItem
              label="Complaint Impact"
              value={result.breakdown.penalties.complaint}
              max={5}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
