import { useState } from "react";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import {
  FormData,
  ScoringResult,
  Weights,
  ScoringConfig,
} from "@/types/email-calculator";
import { calculateAdvancedMailingScore } from "@/utils/calculateScore";
import { FormField } from "./components/email-calculator/FormField";
import { ResultsDisplay } from "./components/email-calculator/ResultsDisplay";
import {
  averageCampaignFormData,
  excellentCampaignFormData,
  goodFormData,
} from "./constants/form";
import { initialWeights, informationalWeights } from "./constants/weights";
import { calculateHealthRanges } from "./utils/calculateHealthRanges";
import { HealthTable } from "./components/email-calculator/HealthTable";

const initialScoringConfig: ScoringConfig = {
  baseScore: 5,
  maxScore: 10,
};

export function EmailEngagementCalculator() {
  const [formData, setFormData] = useState<FormData>(goodFormData);
  const [weights, setWeights] = useState<Weights>(initialWeights);
  const [scoringConfig, setScoringConfig] =
    useState<ScoringConfig>(initialScoringConfig);
  const [result, setResult] = useState<ScoringResult | null>(null);

  const healthRanges = calculateHealthRanges(
    scoringConfig.baseScore,
    scoringConfig.maxScore
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [id]: type === "checkbox" ? checked : parseFloat(value) || 0,
    }));
  };

  const handleWeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    const weightId = id.replace("Weight", "") as keyof Weights;
    setWeights((prev) => ({
      ...prev,
      [weightId]: parseFloat(value) || 0,
    }));
  };

  const handleSelectPreset = (formData: FormData, weights: Weights) => {
    setFormData(formData);
    setWeights(weights);
    calculateScore(formData, weights);
  };

  const calculateScore = (
    formDataForCalculation: FormData,
    weightsForCalculation: Weights
  ) => {
    const result = calculateAdvancedMailingScore(
      formDataForCalculation,
      weightsForCalculation,
      scoringConfig,
      healthRanges
    );
    setResult(result);
  };

  const renderMetricRow = (
    id: keyof FormData,
    label: string,
    weightId: keyof Weights,
    props: { type: string; step: string; min: number; max?: number }
  ) => (
    <div className="grid items-start grid-cols-2 gap-4">
      <FormField
        id={id}
        label={label}
        value={formData[id] as number}
        onChange={handleInputChange}
        props={props}
      />
      <FormField
        id={`${weightId}Weight`}
        label="Weight"
        value={weights[weightId]}
        onChange={handleWeightChange}
        props={{ type: "number", step: "0.1", min: 0, max: 10 }}
      />
    </div>
  );

  return (
    <div className="container max-w-4xl mx-auto md:p-6">
      <div className="flex flex-col gap-12 mb-12">
        <div>
          <h1 className="flex flex-col gap-2 mb-2 text-2xl font-bold md:flex-row">
            <img
              alt="Email Engagement Calculator"
              className="w-8 h-8"
              src="/favicon-32x32.png"
            />
            Email Engagement Calculator
          </h1>
          <p className="text-muted-foreground">
            Input your email performance metrics as decimals (e.g., 0.02 for 2%)
            and customise their weights to see how they influence the overall
            engagement score.
          </p>
        </div>

        <div className="p-4 border rounded-lg bg-card">
          <h2 className="mb-2 text-lg font-semibold">Scoring Configuration</h2>
          <p>
            The engagement health status is determined by comparing the final
            score against the configured base score and maximum score. Adjust
            these thresholds to define what qualifies as excellent, good,
            average, or poor performance.
          </p>

          <div className="grid grid-cols-1 gap-4 my-4 md:grid-cols-2">
            <div className="grid grid-cols-1 gap-4">
              <FormField
                id="baseScore"
                label="Base Score"
                value={scoringConfig.baseScore}
                onChange={(e) =>
                  setScoringConfig((prev) => ({
                    ...prev,
                    baseScore: parseFloat(e.target.value) || 0,
                  }))
                }
                props={{ type: "number", step: "0.5", min: 0, max: 10 }}
              />
              <FormField
                id="maxScore"
                label="Max Score"
                value={scoringConfig.maxScore}
                onChange={(e) =>
                  setScoringConfig((prev) => ({
                    ...prev,
                    maxScore: parseFloat(e.target.value) || 0,
                  }))
                }
                props={{ type: "number", step: "0.5", min: 1, max: 20 }}
              />
            </div>

            <HealthTable healthRanges={healthRanges} />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-12 md:flex-row">
        <div className="flex-1 space-y-12">
          <div className="space-y-6">
            <div className="grid items-center grid-cols-2 gap-4">
              <h2 className="text-lg font-semibold">Metrics</h2>
              <h2 className="text-lg font-semibold">Weights</h2>
            </div>

            <div className="space-y-4">
              {renderMetricRow("replyRate", "Reply Rate", "reply", {
                type: "number",
                step: "0.001",
                min: 0,
                max: 1,
              })}
              {renderMetricRow("forwardingRate", "Forward Rate", "forward", {
                type: "number",
                step: "0.001",
                min: 0,
                max: 1,
              })}
              {renderMetricRow(
                "unsubscribeRate",
                "Unsubscribe Rate",
                "unsubscribe",
                {
                  type: "number",
                  step: "0.0001",
                  min: 0,
                  max: 1,
                }
              )}
              {renderMetricRow("complaintRate", "Complaint Rate", "complaint", {
                type: "number",
                step: "0.0001",
                min: 0,
                max: 1,
              })}
              {renderMetricRow(
                "surveyCompletionRate",
                "Survey Rate",
                "survey",
                {
                  type: "number",
                  step: "0.001",
                  min: 0,
                  max: 1,
                }
              )}
              {renderMetricRow(
                "conversionRate",
                "Conversion Rate",
                "conversion",
                {
                  type: "number",
                  step: "0.001",
                  min: 0,
                  max: 1,
                }
              )}
              {renderMetricRow(
                "timeSpentReading",
                "Time Spent (seconds)",
                "timeSpent",
                {
                  type: "number",
                  step: "1",
                  min: 0,
                }
              )}
            </div>

            <div className="pt-4 ">
              <Label>Presets</Label>
              <div className="flex flex-wrap items-center gap-2 pt-2">
                <Button
                  id="informational"
                  onClick={() =>
                    handleSelectPreset(
                      {
                        ...goodFormData,
                        isInformational: true,
                      },
                      informationalWeights
                    )
                  }
                  variant="outline"
                >
                  Informational
                </Button>
                <Button
                  id="average-cta"
                  onClick={() =>
                    handleSelectPreset(averageCampaignFormData, initialWeights)
                  }
                  variant="outline"
                >
                  Average CTA
                </Button>
                <Button
                  id="good-cta"
                  onClick={() =>
                    handleSelectPreset(goodFormData, initialWeights)
                  }
                  variant="outline"
                >
                  Good CTA
                </Button>
                <Button
                  id="excellent-cta"
                  onClick={() =>
                    handleSelectPreset(
                      excellentCampaignFormData,
                      initialWeights
                    )
                  }
                  variant="outline"
                >
                  Excellent CTA
                </Button>
              </div>
            </div>

            <Button
              onClick={() => calculateScore(formData, weights)}
              className="w-full"
            >
              Calculate Score
            </Button>
          </div>
        </div>

        <div className="flex-1">
          {result ? (
            <div className="sticky top-6">
              <ResultsDisplay result={result} />
            </div>
          ) : (
            <div className="flex items-center justify-center w-full h-full p-4 rounded-lg bg-muted/50">
              <p className="text-center text-muted-foreground">
                Results will appear here after calculation
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
