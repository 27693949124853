import { TooltipData } from "@/types/email-calculator";

export const tooltips: TooltipData = {
  replyRate: "Good: 0.02-0.03 (2-3%)",
  forwardingRate: "Good: 0.005-0.01 (0.5-1%)",
  unsubscribeRate: "Good: below 0.002 (0.2%)",
  complaintRate: "Should be below 0.001 (0.1%)",
  surveyCompletionRate: "Good: 0.01-0.03 (1-3%)",
  conversionRate: "Good: 0.005-0.02 (0.5-2%)",
  timeSpentReading: "Good: 30+ seconds",
};
