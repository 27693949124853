import {
  MailingScoreParams,
  ScoringConfig,
  ScoringResult,
  Weights,
} from "@/types/email-calculator";
import { HealthRanges } from "./calculateHealthRanges";

export function calculateAdvancedMailingScore(
  formData: MailingScoreParams,
  weights: Weights,
  config: ScoringConfig,
  healthRanges: HealthRanges
): ScoringResult {
  const {
    replyRate,
    forwardingRate,
    unsubscribeRate,
    complaintRate,
    surveyCompletionRate,
    conversionRate,
    timeSpentReading,
    isInformational,
  } = formData;
  const { baseScore, maxScore } = config;

  const calculateEngagementScore = (
    rate: number,
    weight: number,
    cap: number
  ) => Math.min(Math.pow(rate * weight, 1.15), cap);

  const engagementScores = {
    replyScore: calculateEngagementScore(replyRate, weights.reply, 4),
    forwardScore: calculateEngagementScore(forwardingRate, weights.forward, 3),
    surveyScore: calculateEngagementScore(
      surveyCompletionRate,
      weights.survey,
      2
    ),
    conversionScore: calculateEngagementScore(
      conversionRate,
      weights.conversion,
      5
    ),
    /**
     * We subtract 10 seconds off the time spent reading.
     * https://www.statista.com/statistics/1273288/time-spent-brand-emails/
     */
    timeScore: calculateEngagementScore(
      Math.max(0, (timeSpentReading - 10) / 20),
      weights.timeSpent,
      3
    ),
  };

  const totalEngagementScore = Object.values(engagementScores).reduce(
    (a, b) => a + b,
    0
  );

  const calculatePenalty = () => {
    const unsubPenalty = Math.pow(unsubscribeRate * weights.unsubscribe, 1.4);
    const complaintPenalty = Math.pow(complaintRate * weights.complaint, 1.5);
    return unsubPenalty + complaintPenalty;
  };

  const penalties = calculatePenalty();

  const finalScore = Math.max(
    0,
    Math.min(
      maxScore,
      baseScore + totalEngagementScore - penalties + (isInformational ? 0.5 : 0)
    )
  );

  const healthStatus =
    finalScore >= healthRanges.excellentStart
      ? "Excellent"
      : finalScore >= healthRanges.goodStart
      ? "Good"
      : finalScore >= healthRanges.averageStart
      ? "Average"
      : "Poor";

  return {
    score: Number(finalScore.toFixed(2)),
    healthStatus,
    breakdown: {
      baseScore,
      engagementScores,
      penalties: {
        unsubscribe: Math.pow(unsubscribeRate * weights.unsubscribe, 1.3),
        complaint: Math.pow(complaintRate * weights.complaint, 1.4),
      },
      emailType: isInformational ? "Informational" : "Action-Oriented",
    },
  };
}
